// See https://tailwindcss.com/docs/configuration for details
const colors = require("./src/config/colors")

const widths = {
  base: "0px",
  xs: "480px",
  sm: "640px",
  md: "960px",
  lg: "1024px",
  xl: "1160px",
  xxl: "1280px",
}

const gutters = {
  base: "24px",
  xs: "24px",
  sm: "32px",
  md: "32px",
  lg: "40px",
  xl: "48px",
  xxl: "48px",
}

let heights = {}
Object.entries(widths).map(([key, value]) => {
  heights[`v${key}`] = { raw: `(min-height: ${value})` }
})

const screens = {
  ...widths,
  ...heights,
}

module.exports = {
  theme: {
    extend: {
      colors: colors,
      opacity: {
        10: "0.1",
        20: "0.2",
        25: "0.25",
        50: "0.5",
        75: "0.75",
      },
      spacing: {
        72: "18rem",
        84: "21rem",
        96: "24rem",
        "1/2": "50%",
        "1/3": "33.3333333%",
        "2/3": "66.6666666%",
        "1/4": "25%",
        "3/4": "75%",
        "1/5": "20%",
        "2/5": "40%",
        "3/5": "60%",
        "4/5": "80%",
        "1/6": "16.6666666%",
        "5/6": "83.3333333%",
        "1/7": "14.2857143%",
        "2/7": "28.5714285%",
        "3/7": "42.85714285%",
        "4/7": "57.14285714%",
        "5/7": "71.42857142%",
        "6/7": "85.71428571%",
        "1/8": "12.5%",
        "2/8": "25%",
        "3/8": "37.5%",
        "4/8": "50%",
        "5/8": "62.5%",
        "6/8": "75%",
        "7/8": "87.5%",
        "1/9": "11.111111%",
        "2/9": "22.222222%",
        "3/9": "33.333333%",
        "4/9": "44.444444%",
        "5/9": "55.555555%",
        "6/9": "66.666666%",
        "7/9": "77.777777%",
        "8/9": "88.888888%",
        "1/10": "10%",
        "2/10": "20%",
        "3/10": "30%",
        "4/10": "40%",
        "5/10": "50%",
        "6/10": "60%",
        "7/10": "70%",
        "8/10": "80%",
        "9/10": "90%",
        full: "100%",
      },
      width: {
        "10vw": "10vw",
        "20vw": "20vw",
        "30vw": "30vw",
        "40vw": "40vw",
        "50vw": "50vw",
        "60vw": "60vw",
        "70vw": "70vw",
        "80vw": "80vw",
        "90vw": "90vw",
      },
      height: {
        "10vh": "10vh",
        "20vh": "20vh",
        "30vh": "30vh",
        "40vh": "40vh",
        "50vh": "50vh",
        "60vh": "60vh",
        "70vh": "70vh",
        "80vh": "80vh",
        "90vh": "90vh",
      },
      inset: {
        "1/2": "50%",
        "1/3": "33.3333333%",
        "2/3": "66.6666666%",
        "1/4": "25%",
        "3/4": "75%",
        "1/5": "20%",
        "2/5": "40%",
        "3/5": "60%",
        "4/5": "80%",
        "1/6": "16.6666666%",
        "5/6": "83.3333333%",
        "1/7": "14.2857143%",
        "2/7": "28.5714285%",
        "3/7": "42.85714285%",
        "4/7": "57.14285714%",
        "5/7": "71.42857142%",
        "6/7": "85.71428571%",
        "-1/2": "-50%",
        "-1/3": "-33.3333333%",
        "-2/3": "-66.6666666%",
        "-1/4": "-25%",
        "-3/4": "-75%",
        "-1/5": "-20%",
        "-2/5": "-40%",
        "-3/5": "-60%",
        "-4/5": "-80%",
        "-1/6": "-16.6666666%",
        "-5/6": "-83.3333333%",
        "-1/7": "-14.2857143%",
        "-2/7": "-28.5714285%",
        "-3/7": "-42.85714285%",
        "-4/7": "-57.14285714%",
        "-5/7": "-71.42857142%",
        "-6/7": "-85.71428571%",
      },
      minHeight: {
        72: "18rem",
        84: "21rem",
        96: "24rem",
        128: "32rem",
        "50vh": "50vh",
        "60vh": "60vh",
        "70vh": "70vh",
        "80vh": "80vh",
        "90vh": "90vh",
      },
      maxHeight: {
        72: "18rem",
        84: "21rem",
        96: "24rem",
        128: "32rem",
        "50vh": "50vh",
        "60vh": "60vh",
        "70vh": "70vh",
        "80vh": "80vh",
        "90vh": "90vh",
      },
    },
    screens: screens,
    gutters: gutters,
  },
  variants: {},
}
